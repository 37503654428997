// Styles
import BrokerExposureContainer from "./BrokerExposure.styles";
import ReportsTab from "../../../../components/Screens/DailyRecs/DailyFlowsCheck/ReportsTab/ReportsTab";
import AlertsTab from "../../../../components/Screens/DailyRecs/DailyFlowsCheck/AlertsTab/AlertsTab";
import ChangeLogTab from "../../../../components/Screens/DailyRecs/DailyFlowsCheck/ChangeLogTab/ChangeLogTab";
import Tabs from "../../../../components/Common/Tabs/Tabs";

interface BrokerExposureProps {}

const powerBI = "https://app.powerbi.com/reportEmbed?reportId=20f8f41b-cd73-4291-bfb7-b13f1bfba02b&autoAuth=true&ctid=bf4450a2-bfcb-472d-8c40-1425eafb1a3b";
const checkName = "broker_exposures";

const BrokerExposure = ({}: BrokerExposureProps) => {
    return (
        <BrokerExposureContainer>
            <p className={"sectionTitle"}>BROKER EXPOSURE</p>
            <Tabs
                defaultTab="reports"
                tabs={[
                    {
                        value: "reports",
                        title: "REPORTS",
                        permissions: ["p_can_view_navs", "p_can_action_navs"],
                        content: <ReportsTab powerBI={powerBI} />,
                    },
                    {
                        value: "alerts",
                        title: "ALERTS",
                        permissions: ["p_can_view_navs", "p_can_action_navs"],
                        content: <AlertsTab checkName={checkName} />,
                    },
                    {
                        value: "change_log",
                        title: "CHANGE LOG",
                        permissions: ["p_can_view_navs", "p_can_action_navs"],
                        content: <ChangeLogTab checkName={checkName} />,
                    },
                ]}
            />
        </BrokerExposureContainer>
    );
};

export default BrokerExposure;
