import { Divider, Grid } from "@mui/material";
import TextField from "components/Common/TextField/TextField";
import React from "react";
import { configurationsInputFields, ConfigurationsInputValues } from "../types";
import { formatNumber } from "utils/common.utils";

// Styles
import ConfigurationsInputFieldsContainer from "./ConfigurationsInputFields.styles";

interface ConfigurationsInputFieldsProps {
    handleChange: (value: number, name: string) => void;
    newConfigurationsValues: any;
    disabled?: boolean;
}

const ConfigurationsInputFields = ({
    disabled,
    handleChange,
    newConfigurationsValues,
}: ConfigurationsInputFieldsProps) => {
    return (
        <ConfigurationsInputFieldsContainer>
            <>
                <p className={"inputTitle"}>CONFIGURATIONS</p>
                <Divider style={{ marginBottom: "15px" }} />
                <Grid
                    container
                    direction="row"
                    spacing={{ xs: 2, md: 2 }}
                    columns={{ xs: 4, sm: 8, md: 12 }}
                >
                    {configurationsInputFields.configurations.map((input) => {
                        if (input.type === "input") {
                            return (
                                <Grid
                                    key={input.name}
                                    item
                                    xs={2}
                                    sm={4}
                                    md={4}
                                >
                                    <label>{input.label}</label>
                                    <div className="textFieldDiv">
                                        <TextField
                                            placeholder={
                                                newConfigurationsValues[
                                                    input.name as keyof ConfigurationsInputValues
                                                ]
                                            }
                                            type={"number"}
                                            change={(e: number) =>
                                                handleChange(e, input.name)
                                            }
                                            value={
                                                newConfigurationsValues[
                                                    input.name as keyof ConfigurationsInputValues
                                                ]
                                            }
                                            disabled={disabled}
                                        />
                                        <div className={"formatted-value"}>
                                            {formatNumber(newConfigurationsValues[ input.name as keyof ConfigurationsInputValues ])}
                                        </div>
                                    </div>
                                </Grid>
                            );
                        }
                    })}
                </Grid>

                <p className={"inputTitle"}>DUBLIN FEE SHEET</p>
                <Divider style={{ marginBottom: "15px" }} />
                <Grid
                    container
                    direction="row"
                    spacing={{ xs: 2, md: 2 }}
                    columns={{ xs: 4, sm: 8, md: 12 }}
                >
                    {configurationsInputFields.dublin_fee_sheet.map((input) => {
                        if (input.type === "input") {
                            return (
                                <Grid
                                    key={input.name}
                                    item
                                    xs={2}
                                    sm={4}
                                    md={4}
                                >
                                    <label>{input.label}</label>
                                    <div className="textFieldDiv">
                                        <TextField
                                            placeholder={
                                                newConfigurationsValues[
                                                    input.name as keyof ConfigurationsInputValues
                                                ]
                                            }
                                            type={"number"}
                                            change={(e: number) =>
                                                handleChange(e, input.name)
                                            }
                                            value={
                                                newConfigurationsValues[
                                                    input.name as keyof ConfigurationsInputValues
                                                ]
                                            }
                                        />
                                        <div className={"formatted-value"}>
                                            {formatNumber(newConfigurationsValues[ input.name as keyof ConfigurationsInputValues ])}
                                        </div>
                                    </div>
                                </Grid>
                            );
                        }
                    })}
                </Grid>
            </>
        </ConfigurationsInputFieldsContainer>
    );
};
export default ConfigurationsInputFields;
