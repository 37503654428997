import { useEffect, useRef, useState } from "react";

// Styles
import ClientConfigContainer from "./ClientConfig.styles";

// Components
import Button from "components/Common/Button/Button";
import Table from "components/Common/Table/Table";
import TableCell from "components/Common/TableCell/TableCell";
import EditableCell from "components/Screens/Admin/ValidationRules/EditableCell/EditableCell";
import AddClientModal from "./AddClientModal/AddClientModal";

// Types
import { clientInputs, ClientValues } from "../../utils/types";

// Context
import { useFees } from "context/Fees.context";
import { useSettings } from "context/Settings.context";

// Utils
import * as Database from "utils/database.utils";
import FloatToCurrencyParser from "../../utils/FloatToCurrencyParser";
import FloatToPercParser from "../../utils/FloatToPercParser";
import {FloatToPercBasisPointParser, PercBasisToFloatParser} from "../../utils/FloatToPercBasisPointParser";
import StringToFloatParser from "../../utils/StringToFloatParser";
import {getCurrentQuarterFromStartMonth} from "utils/fees.utils";

// FontAwesome
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";

// MaterialUI
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "@mui/material";
import calculateMaxHeight from "../../utils/CalculateMaxHeight";
import CheckCell from "../../../../Admin/Permissions/CheckCell/CheckCell";

interface ClientConfigProps {
    fund?: string;
    region?: string;
}

const ClientConfig = ({ fund, region }: ClientConfigProps) => {
    const { updateFeesData, configs, period, getData } = useFees();
    const tableRef = useRef<HTMLDivElement>(null);
    const [maxHeight, setMaxHeight] = useState("100vh");
    const [reload, setReload] = useState(0);
    const [tableIsLoading, setTableIsLoading] = useState<boolean>(false);
    const [filterQuery, setFilterQuery] = useState<string>("");
    const [errors, setErrors] = useState({});
    const [showErrors, setShowErrors] = useState<boolean>(false);
    const [showAddClientModal, setShowAddClientModal] =
        useState<boolean>(false);

    const { setShowLoadingToast, setToast } = useSettings();

    const [newClientValues, setNewClientValues] = useState<ClientValues>({
        separate_account_name: "",
        month: period,
        tranche: 1,
        fund_entry_date: new Date().toISOString().split("T")[0],
        anniversary_period: "Q1",
        currency: "USD",
        mgt_fees_in_out_of_portfolio: " In ",
        account_id: "",
        benchmark_return: "MSCI ACWI ND",
        needs_to_take_custody_nav: "Y",
        performance_fee_rate: 0.0,
        performance_fees_in_out_of_portfolio: " In ",
        type_of_calculation: "FIXED FEE",
        pricing: 0,
        admin_fee: 0,
        annual_fee: 0,
    });

    const columns = [
     {
            id: 452387459,
            title: "ACTIVE",
            sortable: false,
            value: "active",
           cell: (data: any) => (
                 <CheckCell
                    selected={data.active}
                    group={data.id}
                    permission={data.id}
                    reload={(idx: number, value: string) => {
                        return updateFeesData(
                            "active",
                            data.account_id,
                            period,
                            data.tranche,
                            !data.active,
                            {
                                id: data.id,
                                version: data.version,
                            }
                        );
                    }}
                />
            ),
            show: true,
            fullDataRow: true,
            width: "70px",
        },
        {
            id: 7,
            title: "ACCOUNT",
            sortable: false,
            value: "account_id",
           cell: (data: any) => (
                <div>
                    <div>{data.separate_account_name}</div>
                    <div>{data.account_id}</div>
                </div>
            ),
            show: true,
            fullDataRow: true,
            width: "300px",
        },
         {
            id: 77,
            title: "QUARTER",
            sortable: false,
            value: "month",
           cell: (data: any) => (
                <div>{data.month && getCurrentQuarterFromStartMonth(data.month)}</div>
            ),
            show: true,
            fullDataRow: true,
            width: "100px",
        },
        {
            id: 5,
            title: "CURRENCY",
            sortable: false,
            value: "currency",
            cell: (data: any) => (
                <TableCell
                    data={data.currency}
                />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 2,
            title: "TRANCHE",
            sortable: false,
            value: "tranche",
            cell: (data: any) => (
                <TableCell
                    data={data.tranche}
                />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 3,
            title: "FUND ENTRY DATE",
            sortable: false,
            value: "fund_entry_date",
            cell: (data: any) => (
                <TableCell
                    data={new Date(data.fund_entry_date).toLocaleDateString()}
                />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 4,
            title: "ANNIVERSARY PERIOD",
            sortable: false,
            value: "anniversary_period",
            cell: (data: any) => (
                <EditableCell
                    apiMethod="patch"
                    rowId={data.id}
                    validateCellValueTypeBy="text"
                    cellValueType="text"
                    cellValue="comments"
                    rowIdKey={"id"}
                    initialValue={data.anniversary_period}
                    type="selectOptions"
                    onUpdate={(idx: number, value: string) => {
                        return updateFeesData(
                            "anniversary_period",
                            data.account_id,
                            period,
                            data.tranche,
                            value,
                            {
                                id: data.id,
                                version: data.version,
                            }
                        );
                    }}
                    selectOptions={
                        clientInputs.find((inputs) => {
                            return inputs.name === "anniversary_period";
                        })?.options
                    }
                    setTableIsLoading={setTableIsLoading}
                    updateTable={() => setReload(reload + 1)}
                    tableIsLoading={tableIsLoading}
                />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 6,
            title: "MF IN/OUT OF PORTFOLIO",
            sortable: false,
            value: "mgt_fees_in_out_of_portfolio",
            cell: (data: any) => (
                <EditableCell
                    apiMethod="patch"
                    rowId={data.id}
                    validateCellValueTypeBy="text"
                    cellValueType="number"
                    cellValue="comments"
                    rowIdKey={"id"}
                    initialValue={data.mgt_fees_in_out_of_portfolio}
                    type="selectOptions"
                    onUpdate={(idx: number, value: string) => {
                        return updateFeesData(
                            "mgt_fees_in_out_of_portfolio",
                            data.account_id,
                            period,
                            data.tranche,
                            value,
                            {
                                id: data.id,
                                version: data.version,
                            }
                        );
                    }}
                    selectOptions={
                        clientInputs.find((inputs) => {
                            return (
                                inputs.name === "mgt_fees_in_out_of_portfolio"
                            );
                        })?.options
                    }
                    setTableIsLoading={setTableIsLoading}
                    updateTable={() => setReload(reload + 1)}
                    tableIsLoading={tableIsLoading}
                />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 8,
            title: "BENCHMARK RETURN",
            sortable: false,
            value: "benchmark_return",
            cell: (data: any) => (
                <EditableCell
                    apiMethod="patch"
                    rowId={data.id}
                    validateCellValueTypeBy="text"
                    cellValueType="string"
                    cellValue="comments"
                    rowIdKey={"id"}
                    initialValue={data.benchmark_return}
                    type="selectOptions"
                    selectOptions={
                        clientInputs.find((inputs) => {
                            return inputs.name === "benchmark_return";
                        })?.options
                    }
                    onUpdate={(idx: number, value: string) => {
                        return updateFeesData(
                            "benchmark_return",
                            data.account_id,
                            period,
                            data.tranche,
                            value,
                            {
                                id: data.id,
                                version: data.version,
                            }
                        );
                    }}
                    setTableIsLoading={setTableIsLoading}
                    updateTable={() => setReload(reload + 1)}
                    tableIsLoading={tableIsLoading}
                />
            ),
            show: true,
            fullDataRow: true,
            width: "300px",
        },
        {
            id: 9,
            title: "NEEDS TO TAKE CUSTODY NAV",
            sortable: false,
            value: "needs_to_take_custody_nav",
            cell: (data: any) => (
                <EditableCell
                    apiMethod="patch"
                    rowId={data.id}
                    validateCellValueTypeBy="text"
                    cellValueType="number"
                    cellValue="comments"
                    rowIdKey={"id"}
                    initialValue={data.needs_to_take_custody_nav}
                    type="selectOptions"
                    selectOptions={
                        clientInputs.find((inputs) => {
                            return inputs.name === "needs_to_take_custody_nav";
                        })?.options
                    }
                    onUpdate={(idx: number, value: string) => {
                        return updateFeesData(
                            "needs_to_take_custody_nav",
                            data.account_id,
                            period,
                            data.tranche,
                            value,
                            {
                                id: data.id,
                                version: data.version,
                            }
                        );
                    }}
                    setTableIsLoading={setTableIsLoading}
                    updateTable={() => setReload(reload + 1)}
                    tableIsLoading={tableIsLoading}
                />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 10,
            title: "PERFORMANCE FEE RATE",
            sortable: false,
            value: "performance_fee_rate",
            cell: (data: any) => (
                <EditableCell
                    apiMethod="patch"
                    rowId={data.id}
                    validateCellValueTypeBy="text"
                    cellValueType="number"
                    cellValue="comments"
                    rowIdKey={"id"}
                    initialValue={FloatToPercParser(data.performance_fee_rate)}
                    type="numericalField"
                    onUpdate={(idx: number, value: string) => {
                        return updateFeesData(
                            "performance_fee_rate",
                            data.account_id,
                            period,
                            data.tranche,
                            value,
                            {
                                id: data.id,
                                version: data.version,
                            }
                        );
                    }}
                    setTableIsLoading={setTableIsLoading}
                    updateTable={() => setReload(reload + 1)}
                    tableIsLoading={tableIsLoading}
                    inputParser={StringToFloatParser}
                />
            ),
            show: true,
            fullDataRow: true,
        },
        {
            id: 11,
            title: "PF IN OUT OF PORTFOLIO",
            sortable: false,
            value: "performance_fees_in_out_of_portfolio",
            cell: (data: any) => (
                <EditableCell
                    apiMethod="patch"
                    rowId={data.id}
                    validateCellValueTypeBy="text"
                    cellValueType="number"
                    cellValue="comments"
                    rowIdKey={"id"}
                    initialValue={data.performance_fees_in_out_of_portfolio}
                    type="selectOptions"
                    selectOptions={
                        clientInputs.find((inputs) => {
                            return (
                                inputs.name ===
                                "performance_fees_in_out_of_portfolio"
                            );
                        })?.options
                    }
                    onUpdate={(idx: number, value: string) => {
                        return updateFeesData(
                            "performance_fees_in_out_of_portfolio",
                            data.account_id,
                            period,
                            data.tranche,
                            value,
                            {
                                id: data.id,
                                version: data.version,
                            }
                        );
                    }}
                    setTableIsLoading={setTableIsLoading}
                    updateTable={() => setReload(reload + 1)}
                    tableIsLoading={tableIsLoading}
                />
            ),
            show: true,
            fullDataRow: true,
        },

        {
            id: 12,
            title: "TYPE OF CALCULATION",
            sortable: false,
            value: "",
            cell: (data: any) => (
                <EditableCell
                    apiMethod="patch"
                    rowId={data.id}
                    validateCellValueTypeBy="text"
                    cellValueType="text"
                    cellValue="comments"
                    rowIdKey={"id"}
                    initialValue={data.type_of_calculation}
                    type="selectOptions"
                    selectOptions={
                        clientInputs.find((inputs) => {
                            return inputs.name === "type_of_calculation";
                        })?.options
                    }
                    onUpdate={(idx: number, value: string) => {
                        return updateFeesData(
                            "type_of_calculation",
                            data.account_id,
                            period,
                            data.tranche,
                            value,
                            {
                                id: data.id,
                                version: data.version,
                            }
                        );
                    }}
                    setTableIsLoading={setTableIsLoading}
                    updateTable={() => setReload(reload + 1)}
                    tableIsLoading={tableIsLoading}
                />
            ),
            headerColor: "#6AB9CA",
            groupingKey: "Management Fee Config",
            show: true,
            fullDataRow: true,
            width: "300px",
        },
        {
            id: 13,
            title: "PRICING",
            sortable: false,
            value: "",
            cell: (data: any) => (
                <EditableCell
                    apiMethod="patch"
                    rowId={data.id}
                    validateCellValueTypeBy="number"
                    cellValueType="number"
                    cellValue="comments"
                    rowIdKey={"id"}
                    initialValue={FloatToPercBasisPointParser(
                        data.pricing ? parseFloat(data.pricing) : 0
                    )}
                    type="numericalField"
                    onUpdate={(idx: number, value: string) => {
                        return updateFeesData(
                            "config_pricing",
                            data.account_id,
                            period,
                            data.tranche,
                            value,
                            {
                                id: data.id,
                                version: data.version,
                            }
                        );
                    }}
                    setTableIsLoading={setTableIsLoading}
                    updateTable={() => setReload(reload + 1)}
                    tableIsLoading={tableIsLoading}
                    inputParser={PercBasisToFloatParser}
                />
            ),
            headerColor: "#6AB9CA",
            groupingKey: "Management Fee Config",
            show: true,
            fullDataRow: true,
        },
        {
            id: 14,
            title: "ADMIN FEE",
            sortable: false,
            value: "",
            cell: (data: any) => (
                <EditableCell
                    apiMethod="patch"
                    rowId={data.id}
                    validateCellValueTypeBy="number"
                    cellValueType="number"
                    cellValue="comments"
                    rowIdKey={"id"}
                    initialValue={FloatToCurrencyParser(
                        data.admin_fee ? parseFloat(data.admin_fee) : 0.0,
                        data.currency
                    )}
                    type="numericalField"
                    onUpdate={(idx: number, value: string) => {
                        return updateFeesData(
                            "admin_fee",
                            data.account_id,
                            period,
                            data.tranche,
                            value,
                            {
                                id: data.id,
                                version: data.version,
                            }
                        );
                    }}
                    setTableIsLoading={setTableIsLoading}
                    updateTable={() => setReload(reload + 1)}
                    tableIsLoading={tableIsLoading}
                    inputParser={StringToFloatParser}
                />
            ),
            headerColor: "#6AB9CA",
            groupingKey: "Management Fee Config",
            show: true,
            fullDataRow: true,
        },
        {
            id: 15,
            title: "ANNUAL FEE RATE",
            sortable: false,
            value: "",
            cell: (data: any) => {
                return (
                    <EditableCell
                        apiMethod="patch"
                        rowId={data.id}
                        validateCellValueTypeBy="number"
                        cellValueType="number"
                        cellValue="comments"
                        rowIdKey={"id"}
                        initialValue={FloatToCurrencyParser(
                            data.annual_fee ? parseFloat(data.annual_fee) : 0.0,
                            data.currency
                        )}
                        type="numericalField"
                        onUpdate={(idx: number, value: string) => {
                            return updateFeesData(
                                "annual_fee",
                                data.account_id,
                                period,
                                data.tranche,
                                value,
                                {
                                    id: data.id,
                                    version: data.version,
                                }
                            );
                        }}
                        setTableIsLoading={setTableIsLoading}
                        updateTable={() => setReload(reload + 1)}
                        tableIsLoading={tableIsLoading}
                        inputParser={StringToFloatParser}
                    />
                );
            },
            headerColor: "#6AB9CA",
            groupingKey: "Management Fee Config",
            show: true,
            fullDataRow: true,
        },
    ];

    const handleChange = (
        value: string | number,
        name: string,
        type: string
    ) => {
       let dynamicKey = name as keyof ClientValues;

        // pharse the value if it should be a number
        clientInputs.forEach((input) => {
            if (input.name === dynamicKey) {
                if (input.dtype === "number") {
                    if (input.name === "tranche") {
                        value = parseInt(value as string);
                    } else {
                        value = parseFloat(value as string);
                    }
                }
            }
        });

        if (type === "number") {
            if (value !== "") {
                if (name === "tranche") {
                    value = parseInt(value as string);
                } else {
                    value = parseFloat(value as string);
                }
            }
        }

        setNewClientValues({
            ...newClientValues,
            [name]: value,
        });
    };

    const handleAddClient = async (client: ClientValues) => {
        setErrors({});
        let fieldErrors: string[] = [];
        setShowErrors(false);
        setShowLoadingToast({ show: true, message: "ADDING" });

        // loop over payload obj and check if fields are not filled out
        Object.keys(client).forEach(function (key) {
            let dynamicKey = key as keyof ClientValues;

            if (!Boolean(client[dynamicKey]?.toString())) {
                fieldErrors.push(key);
            } else {
                clientInputs.forEach((input) => {
                    if (input.name === dynamicKey) {
                        if (input.dtype === "number") {
                            if (input.name === "tranche") {
                                if (client[dynamicKey] as any < 0) {
                                    fieldErrors.push(key);
                                }
                            } else if (client[dynamicKey] as any <= 0) {
                                fieldErrors.push(key);
                            }
                        }
                    }
                });
            }
        });

        if (fieldErrors.length > 0) {
            setToast({
                show: true,
                message: "Invalid Field Values",
                type: "error",
            });
            console.log(fieldErrors);
        } else {
            const res = await Database.post(`api/fees/client_config/${period}`, client);
            if (res.ok) {
                setReload(reload + 1);
                setToast({
                    show: true,
                    type: "success",
                    message: `"${client.separate_account_name}" client has been added`,
                });
                setShowAddClientModal(false);
            }
            if (res.status === 400) {
                let data = await res.json();
                setErrors(data);
                setShowErrors(true);
                setToast({
                    show: true,
                    type: "error",
                    message: "An error has occured",
                });
            }
             if (res.status === 500) {
                setToast({
                    show: true,
                    type: "error",
                    message: "An error has occured on the server",
                });
            }
        }
        setShowLoadingToast({ show: false, message: "" });
    };

    const handleDateChange = (date: Date) => {
        setNewClientValues({
            ...newClientValues,
            fund_entry_date: date.toISOString().split("T")[0],
        });
    };

    const filterOptions = [
        {
            id: 1,
            name: "Search CONFIGS",
            value: "separate_account_name",
            type: "SEARCH",
        },
    ];

    useEffect(() => {
        calculateMaxHeight(tableRef, setMaxHeight);
        window.addEventListener("resize", () =>
            calculateMaxHeight(tableRef, setMaxHeight)
        );
        return () => {
            window.removeEventListener("resize", () =>
                calculateMaxHeight(tableRef, setMaxHeight)
            );
        };
    }, []);

    useEffect(() => {
        if (reload > 0) {
            getData();
        }
    }, [reload]);

    return (
        <ClientConfigContainer maxHeight={maxHeight}>
            {showAddClientModal && (
                <AddClientModal
                    setShowModal={setShowAddClientModal}
                    newClientValues={newClientValues}
                    setNewClientValues={setNewClientValues}
                    handleChange={handleChange}
                    handleAddClient={handleAddClient}
                    errors={errors}
                    handleDateChange={handleDateChange}
                />
            )}
            <br />
            {/* <SuggestedClients /> */}

            <p className="title">CLIENTS:</p>
            {/* <SearchOptions
                options={[]}
                setFilterQuery={setFilterQuery}
                actions={}
            /> */}
            <Tooltip title={"Create or replace existing configuration"}>
                <Button
                    click={() => setShowAddClientModal(!showAddClientModal)}
                    text={"SUBMIT CLIENT"}
                    icon={faPlus}
                    className="generateBtn"
                />
            </Tooltip>
            <div className="tableDiv" ref={tableRef}>
                <Table
                    data={configs}
                    columns={columns}
                    filterQuery={filterQuery}
                    pagination
                    reload={reload}
                    emptyText="NO CLIENTS"
                    defaultSortKey="account_id"
                    defaultSortOrder="DESC"
                    stickyColumns={[1]}
                />
            </div>
        </ClientConfigContainer>
    );
};
export default ClientConfig;
