import React from "react";

// Styles
import SupplementaryInformationViewContainer from "./SupplementaryInformationView.styles";

interface SupplementaryInformationViewProps {}

const SupplementaryInformationView =
    ({}: SupplementaryInformationViewProps) => {
        return (
            <SupplementaryInformationViewContainer>
                <div className="iframeContainer">
                    <iframe
                        title="Supplementary Information vLive (QS)"
                        src="https://app.powerbi.com/reportEmbed?reportId=9e83ca82-7424-4eab-95e1-a3e79e207dbe&autoAuth=true&ctid=bf4450a2-bfcb-472d-8c40-1425eafb1a3b"
                        width="100%"
                        height="100%"
                        allowFullScreen
                        frameBorder={0}
                    />
                </div>
            </SupplementaryInformationViewContainer>
        );
    };
export default SupplementaryInformationView;
