// Styles
import CurrencyExposureContainer from "./CurrencyExposure.styles";
import ReportsTab from "../../../../components/Screens/DailyRecs/DailyFlowsCheck/ReportsTab/ReportsTab";
import AlertsTab from "../../../../components/Screens/DailyRecs/DailyFlowsCheck/AlertsTab/AlertsTab";
import ChangeLogTab from "../../../../components/Screens/DailyRecs/DailyFlowsCheck/ChangeLogTab/ChangeLogTab";
import Tabs from "../../../../components/Common/Tabs/Tabs";

interface CurrencyExposureProps {}

const powerBI =
    "https://app.powerbi.com/reportEmbed?reportId=4f717bde-ca85-4836-bfca-7a2fe984f1a6&autoAuth=true&ctid=bf4450a2-bfcb-472d-8c40-1425eafb1a3b";
const checkName = "currency_exposure_checks";

const CurrencyExposure = ({}: CurrencyExposureProps) => {
    return (
        <CurrencyExposureContainer>
            <p className={"sectionTitle"}>CURRENCY EXPOSURE</p>
            <Tabs
                defaultTab="reports"
                tabs={[
                    {
                        value: "reports",
                        title: "REPORTS",
                        permissions: ["p_can_view_navs", "p_can_action_navs"],
                        content: <ReportsTab powerBI={powerBI} />,
                    },
                    {
                        value: "alerts",
                        title: "ALERTS",
                        permissions: ["p_can_view_navs", "p_can_action_navs"],
                        content: <AlertsTab checkName={checkName} />,
                    },
                    {
                        value: "change_log",
                        title: "CHANGE LOG",
                        permissions: ["p_can_view_navs", "p_can_action_navs"],
                        content: <ChangeLogTab checkName={checkName} />,
                    },
                ]}
            />
        </CurrencyExposureContainer>
    );
};

export default CurrencyExposure;
