import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

// Styles
import NewNotesContainer from "./NewNotes.styles";

// Contexts
import { useBQMQ } from "context/BQMQ.context";
import { useSettings } from "context/Settings.context";

// Components
import Button from "components/Common/Button/Button";
import DateSelect from "components/Common/DateSelect/DateSelect";
import LoadingSpinner from "components/Common/LoadingSpinner/LoadingSpinner";
import Select from "components/Common/Select/Select";
import RichText from "components/Common/RichText/RichText";
import MultipleFileUpload from "components/Common/MultipleFileUpload/MultipleFileUpload";

// Utils
import * as Database from "utils/database.utils";
import { swapDayAndMonth } from "utils/date.utils";

interface NewNotesProps {}

type SelectOption = {
    value: string;
    label: string;
};

type DropDownType = {
    active: boolean;
    label: string;
    value: string;
};

type FileUploadResponse = {
    file_name: string;
    message: string;
    status: string;
    code: number;
};

type MorningMeetingsDataType = {
    company_name: string;
    company_id: string;
    analyst_name: string;
    analyst_id: string;
    analyst_active: boolean;
};

type MorningMeetingToEdit = {
    company_id: string,
    analyst_id: string,
    date: Date,
    files:Array<{file_id: string; filename: string}>
    notes: Array<string>
}

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const NewNotes = ({}: NewNotesProps) => {
    const [chosenCompany, setChosenCompany] = useState<string>("");
    const [chosenAnalyst, setChosenAnalyst] = useState<string>("");
    const [chosenDate, setChosenDate] = useState<any>(new Date());
    const [noteText, setNoteText] = useState<string>("");
    const [showFileSelect, setShowFileSelect] = useState<boolean>(false);
    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
    const [users, setUsers] = useState<DropDownType[]>([]);
    const [updatedCompanies, setUpdatedCompanies] = useState<SelectOption[]>([]);
    const [originalData, setOriginalData] = useState<MorningMeetingsDataType[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [meetingId, setMeetingId] = useState<string>("");

    const { companies, analysts } = useBQMQ();
    const { setShowLoadingToast, setToast } = useSettings();

    const query = useQuery();
    const meetingNoteIdFromEdit = query.get('meeting_note_id');      

    useEffect(() => {
        if(meetingNoteIdFromEdit) {
            setMeetingId(meetingNoteIdFromEdit)
            const fetchMeetingNoteById = async () => {
                const response = await Database.get(`api/bqmq_voting/meeting-note/${meetingNoteIdFromEdit}`)

                if(response.ok) {
                    const data:{data: MorningMeetingToEdit} = await response.json()

                    console.log(typeof data.data.date)

                    setChosenCompany(data.data.company_id)
                    setChosenAnalyst(data.data.analyst_id)
                    setChosenDate(swapDayAndMonth(data.data.date.toString()))
                    setNoteText(data.data.notes.join('<br/><br/>'))
                    setShowFileSelect(true)
                }
            }
            fetchMeetingNoteById()
        }
    }, [meetingNoteIdFromEdit])

    useEffect(() => {
        const fetchCompaniesAnalysts = async () => {
            setLoading(true);
            const response:Response = await Database.get("api/bqmq_voting/get-new-note-dropdown-items");

            if (response.ok) {
                const data = await response.json();
                setOriginalData(data.data);
            }
            setLoading(false);
        };

        fetchCompaniesAnalysts();
    }, []);

    useEffect(() => {
        setUsers(analysts.filter((a: DropDownType) => a.label && a.active));
        setUpdatedCompanies([{ value: "null", label: "GENERAL" }, ...companies.filter((c: SelectOption) => c.label)]);
    }, [analysts, companies]);

    useEffect(() => {
        if (chosenCompany === "null") return;

        const foundCompany = originalData.find(d => d.company_id === chosenCompany);
        if(!meetingNoteIdFromEdit) {
            if (foundCompany?.analyst_name) {
                setChosenAnalyst(foundCompany.analyst_id);
            }
        }
    }, [chosenCompany, originalData]);

    const handleSubmit = async () => {
        setShowLoadingToast({ show: true, message: "LOADING" });

        if (!noteText || !chosenAnalyst || !chosenCompany) {
            setToast({ show: true, type: "error", message: "Please ensure you have completed all fields." });
            setShowLoadingToast({ show: false, message: "" });
            return;
        }

        const parsedDate = `${chosenDate.toISOString().slice(0, -13)}00:00:00`;
        const noteData = {
            company_id: chosenCompany === "null" ? null : chosenCompany,
            date: parsedDate,
            analyst_id: chosenAnalyst,
            note: noteText,
        };



        if(meetingNoteIdFromEdit) {
            const response:Response = await Database.patch(`api/bqmq_voting/meeting-note/${meetingNoteIdFromEdit}`, noteData)
            if(response.ok) {
                const data = await response.json();
                setMeetingId(data.data.id);
                setShowFileSelect(true);
                setToast({ show: true, type: "success", message: "Successfully edited the note." });
            }

        } else {
            const response:Response = await Database.post("api/bqmq_voting/create-new-meeting-note", noteData);
            if (response.ok) {
                const data = await response.json();
                setMeetingId(data.data.id);
                setShowFileSelect(true);
                setToast({ show: true, type: "success", message: "Successfully added a new note." });
            }

        }



        setShowLoadingToast({ show: false, message: "" });
    };

    const handleFileUpload = async () => {
        setShowLoadingToast({ show: true, message: "Uploading Files" });

        if (!meetingId) {
            setToast({ show: true, type: "error", message: "Invalid meeting note id" });
            setShowLoadingToast({ show: false, message: "" });
            return;
        }

        const formData = new FormData();

        if (selectedFiles.length === 0) {
            setToast({ show: true, type: "error", message: "Please select files to upload" });
            setShowLoadingToast({ show: false, message: "" });
            return;
        }

        selectedFiles.forEach(file => formData.append(file.name, file));

        try {
            const response = await Database.postFile(`api/bqmq_voting/meeting-note/${meetingId}/files`, formData);
            const data = await response.json();
            const uploadResponses: FileUploadResponse[] = data.data.uploaded_files;

            const messages = uploadResponses.map(response => {
                switch (response.code) {
                    case 200:
                        return `Successfully uploaded ${response.file_name}`;
                    case 409:
                        return `File already exists: ${response.file_name}`;
                    default:
                        return `Failed to upload ${response.file_name}`;
                }
            });

            setToast({ show: true, type: messages.every(m => m.startsWith("Successfully")) ? "success" : "error", message: messages.join(". ") });
        } catch (error) {
            setToast({ show: true, type: "error", message: "An error occurred while uploading files. Please try again later." });
        } finally {
            setShowLoadingToast({ show: false, message: "" });
        }
    };

    return (
        <NewNotesContainer fileUploadActive={!showFileSelect}>
            <div className="container">
                <h1 className="pageTitle">{meetingNoteIdFromEdit ? 'EDIT' : 'NEW'} NOTE</h1>
                <hr />
                <div className="input-wrapper">
                    {loading ? (
                        <div className="loadingScreen"><LoadingSpinner /></div>
                    ) : (
                        <div className="notes-input-wrapper">
                            <div className="input-wrapper">
                                <div className="selection">
                                    <div className={meetingNoteIdFromEdit ? "disabled": ''}>
                                        <label>Company Select</label>
                                        <Select
                                            showAll={false}
                                            searchable
                                            filterName="Company"
                                            options={updatedCompanies}
                                            value={chosenCompany}
                                            setValue={setChosenCompany}
                                            disabled={meetingNoteIdFromEdit ? true : false}
                                        />
                                    </div>
                                    <div>
                                        <label>Date Select</label>
                                        <DateSelect
                                            placeholder=""
                                            value={chosenDate}
                                            setValue={setChosenDate}
                                            error={false}
                                        />
                                    </div>
                                    <div>
                                        <label>Analyst Select</label>
                                        <Select
                                            showAll={false}
                                            searchable
                                            filterName="Analyst"
                                            options={users}
                                            value={chosenAnalyst}
                                            setValue={setChosenAnalyst}
                                        />
                                    </div>
                                </div>
                                <RichText setText={setNoteText} value={noteText} edit />
                                <Button text="SUBMIT" click={handleSubmit} />
                            </div>

                            <div className="file-upload-wrapper">
                                <MultipleFileUpload selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles} />
                                <Button text="UPLOAD FILES" click={handleFileUpload} />
                                <p className="file-upload-warning">*File upload will be enabled once you submit a new note.</p>
                            </div>

                        </div>
                    )}
                </div>
            </div>
        </NewNotesContainer>
    );
};

export default NewNotes;
