import React from "react";

// Styles
import AsiaPlViewContainer from "./AsiaPlView.styles";

interface GEFSectorsViewProps {}


const GEFSectorsView = ({}: GEFSectorsViewProps) => {
    return (
        <AsiaPlViewContainer>
            <div className="iframeContainer">
                <iframe
                    title="Sector Scorecards vLive (QS)"
                    src="https://app.powerbi.com/reportEmbed?reportId=5addf6ab-5002-4a02-9003-d42ab221644f&autoAuth=true&ctid=bf4450a2-bfcb-472d-8c40-1425eafb1a3b"
                    width="100%"
                    height="100%"
                    allowFullScreen
                    frameBorder={0}
                />
            </div>
        </AsiaPlViewContainer>
    );
};
export default GEFSectorsView
