// Styles
import LeverageCollateralFutureContainer from "./LeverageCollateralFuture.styles";
import ReportsTab from "../../../../components/Screens/DailyRecs/DailyFlowsCheck/ReportsTab/ReportsTab";
import AlertsTab from "../../../../components/Screens/DailyRecs/DailyFlowsCheck/AlertsTab/AlertsTab";
import ChangeLogTab from "../../../../components/Screens/DailyRecs/DailyFlowsCheck/ChangeLogTab/ChangeLogTab";
import Tabs from "../../../../components/Common/Tabs/Tabs";

interface LeverageCollateralFutureProps {}

const powerBI =
    "https://app.powerbi.com/reportEmbed?reportId=7ed1b71c-6735-483a-af2f-6a6e3acb3a22&autoAuth=true&ctid=bf4450a2-bfcb-472d-8c40-1425eafb1a3b";
const checkName = "leverage_collateral";

const LeverageCollateralFuture = ({}: LeverageCollateralFutureProps) => {
    return (
        <LeverageCollateralFutureContainer>
            <p className={"sectionTitle"}>LEVERAGE + COLLATERAL + FUTURE</p>
            <Tabs
                defaultTab="reports"
                tabs={[
                    {
                        value: "reports",
                        title: "REPORTS",
                        permissions: ["p_can_view_navs", "p_can_action_navs"],
                        content: <ReportsTab powerBI={powerBI} />,
                    },
                    {
                        value: "alerts",
                        title: "ALERTS",
                        permissions: ["p_can_view_navs", "p_can_action_navs"],
                        content: <AlertsTab checkName={checkName} />,
                    },
                    {
                        value: "change_log",
                        title: "CHANGE LOG",
                        permissions: ["p_can_view_navs", "p_can_action_navs"],
                        content: <ChangeLogTab checkName={checkName} />,
                    },
                ]}
            />
        </LeverageCollateralFutureContainer>
    );
};

export default LeverageCollateralFuture;
