import { useHistory } from "react-router-dom";

// FontAwesome
import {
    faBook,
    faBox,
    faCalendar,
    faCog,
    faDatabase,
    faPaintBrush,
    faPieChart,
    faQuestionCircle,
    faSwatchbook,
    faThumbsUp,
    faTree,
    faVial,
    faLineChart,
    faRocket
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Components
import AnalyticsQueue from "./AnalyticsQueue/AnalyticsQueue";
import Logout from "./Logout/Logout";
import MenuItem from "./MenuItem/MenuItem";
import MenuLogo from "./MenuLogo/MenuLogo";
import NotificationsList from "./NotificationsList/NotificationsList";
import ProfileButton from "./ProfileButton/ProfileButton";

// Utils
import { useSettings } from "context/Settings.context";
import { hideBasedOnRoutes } from "utils/common.utils";

// Styles
import { MainMenuContainer } from "./MainMenu.styles";

export default function MainMenu() {
    const history = useHistory();

    const hide = hideBasedOnRoutes(["/home", "/portfolio-live"]);

    const {
        route,
        setShowGlobalSearchBox,
        theme,
        setTheme,
    }: {
        route: string;
        setShowGlobalSearchBox: Function;
        theme: "light" | "dark" | "highContrast";
        setTheme: Function;
    } = useSettings();

    const themesList = {
        dark: "highContrast",
        highContrast: "light",
        light: "dark",
    };

    return (
        <MainMenuContainer>
            <Logout />
            <div className="menuList">
                <MenuLogo />
                {!hide && (
                    <>
                        {/* <MenuItem
                            isActive={false}
                            text={"SEARCH"}
                            icon={
                                <FontAwesomeIcon icon={faSearch} fixedWidth />
                            }
                            click={() => setShowGlobalSearchBox(true)}
                            permissions={[]}
                        />
                        <MenuItem
                            isActive={route.includes("/dashboards")}
                            text={"DASHBOARDS"}
                            icon={
                                <FontAwesomeIcon
                                    icon={faChartLine}
                                    fixedWidth
                                />
                            }
                            click={() => history.push("/dashboards")}
                            permissions={[]}
                        /> */}
                        <MenuItem
                            id={"dataMainMenuItem"}
                            isActive={route.includes("/data")}
                            text={"DATA"}
                            icon={
                                <FontAwesomeIcon icon={faDatabase} fixedWidth />
                            }
                            click={() => history.push("/data")}
                            permissions={["p_list_extractions"]}
                        />
                        <MenuItem
                            id={"bqmqMainMenuItem"}
                            text={"QUALITY"}
                            isActive={route.includes("/voting")}
                            icon={
                                <FontAwesomeIcon icon={faThumbsUp} fixedWidth />
                            }
                            click={() => history.push("/voting")}
                            permissions={[
                                "P_bqmq_can_host",
                                "p_bqmq_can_view_results",
                                "p_bqmq_can_vote",
                            ]}
                        />

                        <MenuItem
                            id={"feesMainMenuItem"}
                            text={"FEES"}
                            isActive={route.includes("/fees")}
                            icon={<FontAwesomeIcon icon={faBox} fixedWidth />}
                            click={() =>
                                history.push("/fees/dublin?tab=global")
                            }
                            permissions={[
                                "p_can_view_fees",
                                "p_can_action_fees",
                            ]}
                        />

                        <MenuItem
                            id={"adaMainMenuItem"}
                            text={"PORTFOLIO"}
                            isActive={route.includes("/ada")}
                            icon={
                                <FontAwesomeIcon icon={faPieChart} fixedWidth />
                            }
                            click={() => history.push("/ada/snapshot")}
                            permissions={[
                                "p_bqmq_can_host",
                                "p_bqmq_can_view_results",
                                "p_bqmq_can_vote",
                            ]}
                        />

                        <MenuItem
                            id={"sustainabilityMainMenuItem"}
                            text={"SUSTAINABILITY"}
                            isActive={route.includes("/sustainability")}
                            icon={<FontAwesomeIcon icon={faTree} fixedWidth />}
                            click={() => history.push("/sustainability/ghg")}
                            permissions={[
                                "p_bqmq_can_host",
                                "p_bqmq_can_view_results",
                                "p_bqmq_can_vote",
                            ]}
                        />

                        <MenuItem
                            id={"dailyRecsMainMenuItem"}
                            text={"DAILY RECS"}
                            isActive={route.includes("/reconciliation")}
                            icon={
                                <FontAwesomeIcon icon={faCalendar} fixedWidth />
                            }
                            click={() =>
                                history.push(
                                    "/reconciliation/inputs/incoming_files"
                                )
                            }
                            permissions={[
                                "p_can_view_navs",
                                "p_can_action_navs",
                            ]}
                        />

                        <MenuItem
                            id={"growthVotingMainMenu"}
                            text={"GROWTH V."}
                            isActive={route.includes("/growth-voting")}
                            icon={
                                <FontAwesomeIcon icon={faLineChart} fixedWidth />
                            }
                            click={() =>
                                history.push(
                                    "/growth-voting"
                                )
                            }
                            permissions={["p_can_view_growth_voting"]}
                        />

                        <MenuItem
                            id={"researchMainMenuItem"}
                            text={"RESEARCH"}
                            isActive={route.includes("/research")}
                            icon={<FontAwesomeIcon icon={faVial} fixedWidth />}
                            click={() =>
                                history.push("/research/morning-meeting")
                            }
                            permissions={[
                                "P_bqmq_can_host",
                                "p_bqmq_can_view_results",
                                "p_bqmq_can_vote",
                            ]}
                        />

                        <MenuItem
                            id={"chronographMainMenuItem"}
                            text={"CHRONOGRAPH"}
                            isActive={route.includes("/chronograph")}
                            icon={
                                <FontAwesomeIcon
                                    icon={faSwatchbook}
                                    fixedWidth
                                />
                            }
                            click={() => history.push("/chronograph/refresh")}
                            permissions={["p_chron_can_refresh"]}
                        />

                        <MenuItem
                            id={"missionControlMainMenuItem"}
                            text={"MISSION CONTROL"}
                            isActive={route.includes("/mission-control")}
                            icon={
                                <FontAwesomeIcon
                                    icon={faRocket}
                                    fixedWidth
                                />
                            }
                            click={() => history.push("/mission-control/fund-metrics")}
                            permissions={["p_can_view_mission_control", "p_can_view_mission_control_public"]}
                        />

                        <MenuItem
                            id={"bqmqMainMenuItem"}
                            text={"HELP"}
                            isActive={route.includes("/help")}
                            icon={
                                <FontAwesomeIcon
                                    icon={faQuestionCircle}
                                    fixedWidth
                                />
                            }
                            click={() =>
                                history.push(
                                    "/help/documentation/help/Home.help.mdx"
                                )
                            }
                            permissions={["p_can_view_help"]}
                        />
                    </>
                )}
            </div>

            <div className="menuList">
                {!hide && (
                    <>
                        <MenuItem
                            id={"bqmqMainMenuItem"}
                            text={"DOCS"}
                            isActive={route.includes("/docs")}
                            icon={<FontAwesomeIcon icon={faBook} fixedWidth />}
                            click={() =>
                                history.push(
                                    "/docs/documentation/dev/Home.doc.mdx"
                                )
                            }
                            permissions={["p_can_view_docs"]}
                        />

                        <AnalyticsQueue />
                        <NotificationsList />
                    </>
                )}
                <MenuItem
                    isActive={false}
                    text={"CHANGE THEME"}
                    icon={<FontAwesomeIcon icon={faPaintBrush} fixedWidth />}
                    click={() => setTheme(themesList[theme])}
                    permissions={["p_theme_can_toggle"]}
                />
                <MenuItem
                    id={"adminMainMenuBtn"}
                    text={"ADMIN"}
                    isActive={route.includes("/admin")}
                    icon={<FontAwesomeIcon icon={faCog} fixedWidth />}
                    click={() => history.push("/admin/permissions")}
                    permissions={["s_update_permissions"]}
                />

                <ProfileButton />
            </div>
        </MainMenuContainer>
    );
}
