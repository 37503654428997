// Styles
import IframeContainer from "../IframeContainer.styles";

type Props = {};

export default function FundMetrics({}: Props) {
    return (
        <IframeContainer>
            <div className="iframeContainer">
                <iframe
                    src="https://app.powerbi.com/reportEmbed?reportId=2ea7197c-266c-4f93-ac51-a52cc1bdce84&autoAuth=true&ctid=bf4450a2-bfcb-472d-8c40-1425eafb1a3b"
                    title="Fund Metrics vLive (QS)"
                    width="100%"
                    height="100%"
                    allowFullScreen
                    frameBorder={0}
                />
            </div>
        </IframeContainer>
    );
}
