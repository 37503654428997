import React from "react";

// Styles
import ForwardsNDFExposureEMIRContainer from "./ForwardsNDFExposureEMIR.styles";

// Components
import Tabs from "components/Common/Tabs/Tabs";
import ReportsTab from "../../../../components/Screens/DailyRecs/DailyFlowsCheck/ReportsTab/ReportsTab";
import AlertsTab from "../../../../components/Screens/DailyRecs/DailyFlowsCheck/AlertsTab/AlertsTab";
import ChangeLogTab from "../../../../components/Screens/DailyRecs/DailyFlowsCheck/ChangeLogTab/ChangeLogTab";

interface ForwardsNDFExposureEMIRProps {}

const powerBI =
    "https://app.powerbi.com/reportEmbed?reportId=8fe8023f-84fb-44b1-8d15-47e889079338&autoAuth=true&ctid=bf4450a2-bfcb-472d-8c40-1425eafb1a3b";
const forwardsCheckName = "forwards";
const ndfCheckName = "ndf_exposures";
const emirCheckName = "emir";

const ForwardsNDFExposureEMIR = ({}: ForwardsNDFExposureEMIRProps) => {
    return (
        <ForwardsNDFExposureEMIRContainer>
            <p className={"sectionTitle"}>FORWARDS + NDF EXPOSURE + EMIR</p>
            <Tabs
                defaultTab="reports"
                tabs={[
                    {
                        value: "reports",
                        title: "REPORTS",
                        permissions: ["p_can_view_navs", "p_can_action_navs"],
                        content: <ReportsTab powerBI={powerBI} />,
                    },
                    {
                        value: "forwards_alerts",
                        title: "FORWARDS ALERTS",
                        permissions: ["p_can_view_navs", "p_can_action_navs"],
                        content: <AlertsTab checkName={forwardsCheckName} />,
                    },
                    {
                        value: "forwards_change_log",
                        title: "FORWARDS CHANGE LOG",
                        permissions: ["p_can_view_navs", "p_can_action_navs"],
                        content: <ChangeLogTab checkName={forwardsCheckName} />,
                    },
                    {
                        value: "ndf_alerts",
                        title: "NDF ALERTS",
                        permissions: ["p_can_view_navs", "p_can_action_navs"],
                        content: <AlertsTab checkName={ndfCheckName} />,
                    },
                    {
                        value: "ndf_change_log",
                        title: "NDF CHANGE LOG",
                        permissions: ["p_can_view_navs", "p_can_action_navs"],
                        content: <ChangeLogTab checkName={ndfCheckName} />,
                    },
                    {
                        value: "emir_alerts",
                        title: "EMIR ALERTS",
                        permissions: ["p_can_view_navs", "p_can_action_navs"],
                        content: <AlertsTab checkName={emirCheckName} />,
                    },
                    {
                        value: "emir_change_log",
                        title: "EMIR CHANGE LOG",
                        permissions: ["p_can_view_navs", "p_can_action_navs"],
                        content: <ChangeLogTab checkName={emirCheckName} />,
                    },
                ]}
            />
        </ForwardsNDFExposureEMIRContainer>
    );
};
export default ForwardsNDFExposureEMIR;
