// Styles
import BenchmarksContainer from "./Benchmarks.styles";
import ReportsTab from "../../../../components/Screens/DailyRecs/DailyFlowsCheck/ReportsTab/ReportsTab";
import AlertsTab from "../../../../components/Screens/DailyRecs/DailyFlowsCheck/AlertsTab/AlertsTab";
import ChangeLogTab from "../../../../components/Screens/DailyRecs/DailyFlowsCheck/ChangeLogTab/ChangeLogTab";
import Tabs from "../../../../components/Common/Tabs/Tabs";

interface BenchmarksProps {}

const powerBI = "https://app.powerbi.com/reportEmbed?reportId=4a7b4333-93f6-4695-8c8d-13e13618ba30&autoAuth=true&ctid=bf4450a2-bfcb-472d-8c40-1425eafb1a3b";
const checkName = "benchmarks";

const Benchmarks = ({}: BenchmarksProps) => {
    return (
        <BenchmarksContainer>
            <p className={"sectionTitle"}>BENCHMARKS</p>
            <Tabs
                defaultTab="reports"
                tabs={[
                    {
                        value: "reports",
                        title: "REPORTS",
                        permissions: ["p_can_view_navs", "p_can_action_navs"],
                        content: <ReportsTab powerBI={powerBI} />,
                    },
                ]}
            />
        </BenchmarksContainer>
    );
};

export default Benchmarks;
