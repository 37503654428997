import React from "react";

// Styles
import SBTIViewContainer from "./SBTIView.styles";

interface SBTIViewProps {}

const SBTIView = ({}: SBTIViewProps) => {
    return (
        <SBTIViewContainer>
            <div className="iframeContainer">
                <iframe
                    title="ESG - SBTi vLive (QS)"
                    src="https://app.powerbi.com/reportEmbed?reportId=90ea535d-0d8b-4b07-853e-ef791562339d&autoAuth=true&ctid=bf4450a2-bfcb-472d-8c40-1425eafb1a3b"
                    width="100%"
                    height="100%"
                    allowFullScreen
                    frameBorder={0}
                />
            </div>
        </SBTIViewContainer>
    );
};
export default SBTIView;
