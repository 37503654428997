// FontAwesome
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Styles
import { useEffect, useState } from "react";
import TextFieldContainer from "./TextField.styles";

type Props = {
    placeholder: any;
    value: any;
    change: Function;
    disabled?: boolean;
    error?: boolean;
    type?: "text" | "number";
    label?: string;
    displayIcon?: boolean;
    errorText?: string;
    step?: string;
};

export default function TextField({
    placeholder,
    value,
    change,
    disabled,
    error = false,
    type,
    label,
    displayIcon = true,
    errorText,
    step = "1",
}: Props) {
    const [showErrors, setShowErrors] = useState<boolean | undefined>(error);

    useEffect(() => {
        setShowErrors(error);
    }, [error]);
    return (
        <TextFieldContainer>
            <label>{label}</label>
            <div
                className={
                    (showErrors ? "textField error" : "textField ") +
                    (disabled ? "disabled" : "")
                }
            >
                <input
                    type={type}
                    step={step}
                    placeholder={placeholder}
                    value={value}
                    onChange={(e) => {
                        change(e.target.value);
                        setShowErrors(false);
                    }}
                    disabled={disabled}
                />
                {displayIcon && value && !disabled ? (
                    <FontAwesomeIcon
                        icon={faTimesCircle}
                        fixedWidth
                        onClick={() => change("")}
                    />
                ) : null}
            </div>
            {errorText && showErrors ? (
                <p className="errorText">{errorText}</p>
            ) : null}
        </TextFieldContainer>
    );
}
