// Styles
import FXRatesContainer from "./FXRates.styles";
import Tabs from "../../../../components/Common/Tabs/Tabs";
import ReportsTab from "../../../../components/Screens/DailyRecs/DailyFlowsCheck/ReportsTab/ReportsTab";
import AlertsTab from "../../../../components/Screens/DailyRecs/DailyFlowsCheck/AlertsTab/AlertsTab";
import ChangeLogTab from "../../../../components/Screens/DailyRecs/DailyFlowsCheck/ChangeLogTab/ChangeLogTab";

interface FXRatesProps {}

const powerBI = "https://app.powerbi.com/reportEmbed?reportId=a516eb6c-4798-4284-a7b2-a122cf5d5b9b&autoAuth=true&ctid=bf4450a2-bfcb-472d-8c40-1425eafb1a3b";
const checkName = "fx_rates";

const FXRates = ({}: FXRatesProps) => {
    return (
        <FXRatesContainer>
            <p className={"sectionTitle"}>FX RATES</p>
            <Tabs
                defaultTab="reports"
                tabs={[
                    {
                        value: "reports",
                        title: "REPORTS",
                        permissions: ["p_can_view_navs", "p_can_action_navs"],
                        content: <ReportsTab powerBI={powerBI} />,
                    },
                ]}
            />
        </FXRatesContainer>
    );
};

export default FXRates;
