// Styles
import CompanyModelViewContainer from "./CompanyModelView.styles";

type Props = {};

export default function CompanyModelView({}: Props) {
    return (
        <CompanyModelViewContainer>
            <div className="iframeContainer">
                <iframe
                    title="Company View - Model"
                    src="https://app.powerbi.com/reportEmbed?reportId=a5340d94-9569-4fa6-8b54-55e265a9aa13&autoAuth=true&ctid=bf4450a2-bfcb-472d-8c40-1425eafb1a3b"
                    width="100%"
                    height="100%"
                    allowFullScreen
                    frameBorder={0}
                />
            </div>
        </CompanyModelViewContainer>
    );
}
