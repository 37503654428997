import styled from "styled-components";

export default styled.div`
    .textField {
        align-items: center;
        cursor: pointer;

        background-color: #eeeeee;
        border-radius: 5px;
        overflow: hidden;
        display: flex;

        input {
            border: none;
            height: 40px;
            font-size: 18px;
            padding-left: 15px;
            outline: none;
            display: flex;
            flex-grow: 1;
            background-color: #eeeeee;
            position: relative;
        }

        svg {
            margin: 0 10px;
            color: #777777;
        }

        :hover {
            background-color: #dddddd;
            input {
                background-color: #dddddd;
            }
        }

        &.error {
            border: 2px solid lightcoral;
            input {
                height: 36px;
                padding-left: 13px;
            }
        }

        &.disabled {
            cursor: unset;
            :hover {
                background-color: #eeeeee;
                input {
                    background-color: #eeeeee;
                }
            }
        }
    }

    .errorText {
        color: red;
        font-size: 14px;
        margin-top: 5px;
        margin-bottom: 10px;
    }
`;
