import { useEffect, useState } from "react";
// Contexts
import { useBQMQ } from "context/BQMQ.context";
// Styles
import MorningMeetingContainer from "./MorningMeeting.styles";

// Components
import SearchOptions from "components/Common/SearchOptions/SearchOptions";
import HomeList from "components/Screens/Home/HomeList/HomeList";
import MorningNotesRow from "components/Screens/Research/MorningNotesRow/MorningNotesRow";

// Utils
import { useSettings } from "context/Settings.context";
import * as Database from "utils/database.utils";

type MorningMeetingsDataType = {
    comment_count: number;
    last_vote_date: string;
    analyst: string;
    active_analyst: boolean;
    company_name: string;
    id: number;
    sector: string;
};

type AnalystType = {
    active: boolean;
    label: string;
};

const MorningMeeting = () => {
    const { setShowLoadingToast } = useSettings();

    const [filterQuery, setFilterQuery] = useState("");
    const [morningMeetingsData, setMorningMeetingsData] = useState<
        MorningMeetingsDataType[]
    >([]);
    const [filteredMorningMeetingsData, setFilteredMorningMeetingsData] =
        useState<MorningMeetingsDataType[]>([]);
    const [users, setUsers] = useState<{ label: string; value: string }[]>([]);
    const { analysts } = useBQMQ();

    useEffect(() => {
        getAnalysts(analysts);
    }, [analysts]);

    const filterOptions = [
        {
            id: 1,
            name: "Search",
            value: "search",
            type: "SEARCH",
        },
        {
            id: 4,
            name: "Analyst",
            value: "analyst",
            type: "DROPDOWN",
            searchable: true,
            options: users,
        },
        {
            id: 3,
            name: "Industry",
            value: "industry",
            type: "DROPDOWN",
            searchable: false,
            options: [
                {
                    label: "Consumer",
                    value: "CONSUMER",
                },
                {
                    label: "Financials",
                    value: "FINANCIALS",
                },
                {
                    label: "Healthcare",
                    value: "HEALTHCARE",
                },
                {
                    label: "Industrials",
                    value: "INDUSTRIALS",
                },
                {
                    label: "Technology",
                    value: "TECH_AND_TELCO",
                },
                {
                    label: "Unknown",
                    value: "UNKNOWN",
                },
            ],
        },
        {
            id: 2,
            name: "Comments",
            value: "comment_count",
            type: "DROPDOWN",
            searchable: false,
            options: [
                {
                    label: "Ascending",
                    value: "asc",
                },
                {
                    label: "Descending",
                    value: "desc",
                },
            ],
        },
    ];

    const getAnalysts = (data: AnalystType[]) => {
        //iterate through the morning meetings data and get all unique analyst names
        setUsers(
            Array.from(
                new Set(
                    data
                        .filter((a: AnalystType) => a.active)
                        .map((a: AnalystType) => a.label)
                )
            )
                .sort((a: string, b: string) => a.localeCompare(b))
                .map((a) => {
                    return {
                        label: a,
                        value: a,
                    };
                })
        );
    };

    const extractFilterFromQuery = (filter: string) => {
        //extract all the filter values from the filter query,
        // if not present default to empty string
        return (
            (filterQuery.includes(filter) &&
                filterQuery.split(`&filter_${filter}=`).pop()?.split("&")[0]) ||
            ""
        );
    };

    const getAllMorningMeetings = async () => {
        setShowLoadingToast({
            show: true,
            message: "LOADING",
        });
        const response = await Database.get(
            "api/bqmq_voting/get-all-morning-meetings"
        );
        if (response.ok) {
            const data = await response.json();
            let formattedData = formatMeetingData(data.data);
            setMorningMeetingsData(formattedData);
            setFilteredMorningMeetingsData(formattedData);
        }
        setShowLoadingToast({
            show: false,
            message: "LOADING",
        });
    };

    const formatMeetingData = (data: MorningMeetingsDataType[]) => {
        return data.map((meeting: MorningMeetingsDataType) =>
            meeting.sector === "TECH_&_TELCO" ||
            meeting.sector === "TECH & TELCO"
                ? { ...meeting, sector: "TECH_AND_TELCO" }
                : meeting
        );
    };

    const filterAndSortMorningMeetingNotes = (
        filter: Omit<
            MorningMeetingsDataType,
            | "id"
            | "comment_count"
            | "company_name"
            | "active_analyst"
            | "last_vote_date"
        >,
        sort: string,
        searchValue: string
    ) => {
        // if filter queries not present revert morning meetings
        // data to original
        if (
            searchValue === "" &&
            sort === "" &&
            filter.analyst === "" &&
            filter.sector === ""
        ) {
            setFilteredMorningMeetingsData(morningMeetingsData);
            return;
        }
        setFilteredMorningMeetingsData(
            morningMeetingsData
                .filter(function (item) {
                    for (var key in filter) {
                        if (
                            filter[key as keyof typeof filter] !== "" &&
                            item[key as keyof MorningMeetingsDataType] !=
                                filter[key as keyof typeof filter]
                        )
                            return false;
                    }
                    return true;
                })
                .filter((meeting: MorningMeetingsDataType) =>
                    meeting.company_name
                        .toLowerCase()
                        .includes(searchValue.toLowerCase())
                )
                .sort(
                    (
                        a: MorningMeetingsDataType,
                        b: MorningMeetingsDataType
                    ) => {
                        if (sort === "desc") {
                            return b.comment_count - a.comment_count;
                        } else if (sort === "asc")
                            return a.comment_count - b.comment_count;
                        else {
                            //sort alphabetically if sort is not supplied
                            return a.company_name.localeCompare(b.company_name);
                        }
                    }
                )
        );
    };

    useEffect(() => {
        getAllMorningMeetings();
    }, []);

    useEffect(() => {
        let filter: Omit<
            MorningMeetingsDataType,
            | "id"
            | "comment_count"
            | "company_name"
            | "active_analyst"
            | "last_vote_date"
        > = {
            sector: "",
            analyst: "",
        };

        filter["sector"] = extractFilterFromQuery("industry");
        filter["analyst"] = extractFilterFromQuery("analyst");

        let search = extractFilterFromQuery("search");
        let commentSort = extractFilterFromQuery("comment_count");

        filterAndSortMorningMeetingNotes(filter, commentSort, search);
    }, [filterQuery]);

    return (
        <MorningMeetingContainer>
            <SearchOptions
                setFilterQuery={setFilterQuery}
                options={filterOptions}
                noDebounce
            />
            <HomeList
                data={filteredMorningMeetingsData}
                row={(row: any) => <MorningNotesRow data={row} />}
                title={"MORNING MEETING NOTES"}
                countColor={""}
                loading={false}
                maxRows={1000}
            />
        </MorningMeetingContainer>
    );
};
export default MorningMeeting;
