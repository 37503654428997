import React from "react";

// Styles
import SFDRViewContainer from "./SFDRView.styles";

interface SFDRViewProps {}

const SFDRView = ({}: SFDRViewProps) => {
    return (
        <SFDRViewContainer>
            <div className="iframeContainer">
                <iframe
                    title="ESG - SFDR vLive (QS)"
                    src="https://app.powerbi.com/reportEmbed?reportId=f335f166-ca9c-49a8-8918-2baf8913b893&autoAuth=true&ctid=bf4450a2-bfcb-472d-8c40-1425eafb1a3b"
                    width="100%"
                    height="100%"
                    allowFullScreen
                    frameBorder={0}
                />
            </div>
        </SFDRViewContainer>
    );
};
export default SFDRView;
