import { useEffect, useState } from "react";

// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faChevronLeft,
    faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

// Utils
import { useSettings } from "context/Settings.context";

// Components
import BQMQ from "./Pages/BQMQ/BQMQ";
import Data from "./Pages/Data/Data";
import Admin from "./Pages/Admin/Admin";
import Dashboards from "./Pages/Dashboards/Dashboards";
import Fees from "./Pages/Fees/Fees";
import Chronograph from "./Pages/Chronograph/Chronogrpah";
import Docs from "./Pages/Docs/Docs";
import Help from "./Pages/Help/Help";
import DailyRecs from "./Pages/DailyRecs/DailyRecs";
import Research from "./Pages/Research/Research";
import ADA from "./Pages/ADA/ADA";
import Sustainability from "./Pages/Sustainability/Sustainability";
import AskAda from "./Pages/AskAda/AskAda";
import GrowthVoting from "./Pages/GrowthVoting/GrowthVoting";
import MissionControl from "./Pages/MissionControl/MissionControl";

// Styles
import SubMenuContainer from "./SubMenu.styles";

export default function SubMenu() {
    // #######################
    //    Menu Page Control
    // #######################
    const { route } = useSettings();

    const [menuPage, setMenuPage] = useState("add");

    useEffect(() => {
        if (route.includes("/data")) setMenuPage("data");
        if (route.includes("/voting")) setMenuPage("bqmq");
        if (route.includes("/docs")) setMenuPage("docs");
        if (route.includes("/help")) setMenuPage("help");
        if (route.includes("/admin")) setMenuPage("admin");
        if (route.includes("/dashboards")) setMenuPage("dashboards");
        if (route.includes("/fees")) setMenuPage("fees");
        if (route.includes("/chronograph")) setMenuPage("chronograph");
        if (route.includes("/reconciliation")) setMenuPage("dailyrecs");
        if (route.includes("/ada")) setMenuPage("ada");
        if (route.includes("/research")) setMenuPage("research");
        if (route.includes("/sustainability")) setMenuPage("sustainability");
        if (route.includes("/ask-ada")) setMenuPage("askAda");
        if (route.includes("/growth-voting")) setMenuPage("growthVoting");
        if (route.includes("/mission-control")) setMenuPage("missionControl");
    }, [route]);

    // #######################
    // Menu Width and Resizing
    // #######################
    const { subMenuMode, setSubMenuMode, subMenuWidth, setSubMenuWidth } =
        useSettings();
    const [isDragging, setIsDragging] = useState(false);

    const startDrag = (e: React.MouseEvent) => {
        setIsDragging(true);
        window.addEventListener("mouseup", stopDrag, false);
        window.addEventListener("mousemove", updateWidth, false);
    };

    const stopDrag = (e: any) => {
        window.removeEventListener("mouseup", stopDrag, false);
        window.removeEventListener("mousemove", updateWidth, false);
        setIsDragging(false);
    };

    const updateWidth = (e: MouseEvent) => {
        if (e.clientX > 80 && e.clientX < 600) {
            setSubMenuWidth(e.clientX - 70);
        } else if (e.clientX > 600) {
            setSubMenuWidth(600);
        } else {
            setSubMenuWidth(10);
        }
    };

    const closeMenu = () => {
        setSubMenuMode("close");
        setTimeout(() => {
            setSubMenuWidth(15);
        }, 200);
    };

    const openMenu = () => {
        setSubMenuMode("open");
        setTimeout(() => {
            setSubMenuWidth(250);
        }, 200);
    };

    useEffect(() => {
        // If stopped dragging and menu is too small, automatically minimise menu
        if (subMenuWidth < 200 && !isDragging) {
            closeMenu();
        } else {
            setSubMenuMode("init");
        }
    }, [subMenuWidth, isDragging]);

    return (
        <SubMenuContainer
            id={"subMenu"}
            className={subMenuMode}
            style={{ minWidth: subMenuWidth, maxWidth: subMenuWidth }}
            // @ts-ignore
            width={subMenuWidth}
        >
            <div
                className={"handle " + isDragging}
                onMouseDown={startDrag}
                // onMouseUpCapture={stopDrag}
            />

            <div
                className={"minimiseBtn " + subMenuMode}
                onClick={() => (subMenuWidth === 15 ? openMenu() : closeMenu())}
            >
                <FontAwesomeIcon
                    icon={subMenuWidth === 15 ? faChevronRight : faChevronLeft}
                    fixedWidth
                />
            </div>

            {
                {
                    data: <Data />,
                    bqmq: <BQMQ />,
                    fees: <Fees />,
                    chronograph: <Chronograph />,
                    dailyrecs: <DailyRecs />,
                    docs: <Docs />,
                    help: <Help />,
                    admin: <Admin />,
                    dashboards: <Dashboards />,
                    ada: <ADA />,
                    research: <Research />,
                    sustainability: <Sustainability />,
                    askAda: <AskAda />,
                    growthVoting: <GrowthVoting />,
                    missionControl: <MissionControl />
                }[menuPage]
            }
        </SubMenuContainer>
    );
}
