import React from "react";

// Styles
import NOPATAssumptionsViewContainer from "./NOPATAssumptionsView.styles";

interface NOPATAssumptionsViewProps {}

const NOPATAssumptionsView = ({}: NOPATAssumptionsViewProps) => {
    return (
        <NOPATAssumptionsViewContainer>
            <div className="iframeContainer">
                <iframe
                    title="NOPAT Assumptions - vLive (QS)"
                    src="https://app.powerbi.com/reportEmbed?reportId=1a7ceeaa-ae81-48e6-94dd-ce93dddb519b&autoAuth=true&ctid=bf4450a2-bfcb-472d-8c40-1425eafb1a3b"
                    width="100%"
                    height="100%"
                    allowFullScreen
                    frameBorder={0}
                />
            </div>
        </NOPATAssumptionsViewContainer>
    );
};
export default NOPATAssumptionsView;
